
import {Toast, Search, Icon} from 'vant'
export default {
  components:{
    'van-search': Search,
    'van-icon': Icon,
  },
  data() {
    return {
      value: '',
      apis:{
        searchRecord:"/searchRecord",//搜索记录查询
        listHotSearch:"/listHotSearch",//热门搜索
      },
      hotList:[],
      historyList:[],//历史记录
      params:{}

    };
  },
  mounted () {
    this.searchlist();
    this.hotFunc();


  },
  created(){

  },

  methods: {
    hotFunc(){//热门搜索

      const me = this;
      me.$get({
        url:me.apis.listHotSearch,
        params:{
          topNum:10
        }
      }).then(rep=>{
        console.log(rep);
        me.hotList=rep
      })



    },
    searchlist(){
      const me = this;

      if(!me.$global.token){
        return
      }
      me.$post({
        url:me.apis.searchRecord,
        params:{
          pageIndex:1,
          pageSize:10,
          token:me.$global.token
        }
      }).then(rep=>{
        console.log(rep);
        me.historyList = rep.data
      })
    },


    onSearch(val) {
      this.$router.push(`/searchCont/?content=${val}`)
    },
    onCancel() {
      this.$router.go(-1)
    }
  }
}
